<template>
  <NotificationsTable />
<!--  <CTabs class="zq&#45;&#45;tabs">-->
<!--    <CTab title="Notification"><NotificationsTable /></CTab>-->
<!--    <CTab title="Member"><MemberMessagesTable/></CTab>-->
<!--    <CTab title="Account"><AccountMessagesTable /></CTab>-->
<!--  </CTabs>-->
</template>
<script>

import MemberMessagesTable from "./tables/MemberMessagesTable";
import NotificationsTable from "./tables/NotificationsTable";
import AccountMessagesTable from "./tables/AccountMessagesTable";

export default {
  name: 'Messages',
  components: {
    AccountMessagesTable,
    NotificationsTable,
    MemberMessagesTable,
  },
  data() {
    return {}
  },
};
</script>

<style lang="scss">

</style>