<template>
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    :isCreateButtonVisible="false"
    model="accountMessages"
  />
</template>
<script>
import { accountMessages } from '@/config/descriptions/accountMessages';
import { accountMessagesTexts } from '@/config/pageTexts/accountMessages.json';

export default {
  name: 'AccountMessagesTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'accountMessages/accountMessages',
          fields: 'accountMessages/fields',
          sortableFields: 'accountMessages/sortableFields',
          pages: 'accountMessages/pages',
          loading: 'accountMessages/loading',
          resultCount: 'accountMessages/resultCount',
          totalRecords: 'accountMessages/totalRecords',
          originalFields: 'accountMessages/originalFields',
          searchableAllFields: 'accountMessages/searchableAllFields',
          success: 'accountMessages/success',
          message: 'accountMessages/message',
        },
        actions: {
          fields: 'accountMessages/handleFields',
          delete: 'accountMessages/handleDeleteAccountMessages',
          reset: 'accountMessages/handleClearFilter',
          query: 'accountMessages/handleGetAccountMessagesByQuery',
        },
      },
      settings: {
        describe: { ...accountMessages },
        page: { ...accountMessagesTexts },
        statuses: ['All', 'Draft', 'Active', 'Expired', 'Cancelled', 'Deleted'],
      },
      route: {
        create: 'CreateAccountMessage',
        edit: 'EditAccountMessage',
        preview: 'PreviewAccountMessage',
      },
    };
  },
};
</script>